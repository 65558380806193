<template>
	<AuthWrapper>
		<p class="auth-notice">
			Don&rsquo;t have an account?
			<router-link to="/register">Sign up now</router-link>
		</p>
		<div class="auth-contents">
			<a-form name="signin" layout="vertical">
				<sdHeading as="h3">
					Sign in to <span class="color-secondary">SmartBusiness</span>
				</sdHeading>
				<a-form-item name="username" label="Email Address *">
					<a-input type="text" name="email" v-model:value="formData.email" />
					<span class="error-messages" v-if="v$.formData.email.$error">
						{{ v$.formData.email.$errors[0].$message }}
					</span>
				</a-form-item>
				<a-form-item name="password" label="Password *">
					<a-input-password
						type="password"
						name="password"
						v-model:value="formData.password"
						placeholder="Password"
					/>
				</a-form-item>
				<span class="error-messages" v-if="v$.formData.password.$error">
					{{ v$.formData.password.$errors[0].$message }}
				</span>
				<div class="auth-form-action">
					<router-link class="forgot-pass-link" to="/forgot_password">
						Forgot password?
					</router-link>
				</div>
				<a-form-item>
					<sdButton
						class="btn-signin"
						htmlType="submit"
						@click="signIn"
						type="primary"
						size="large"
					>
						{{ store.state.actions.isLoading ? "Loading..." : "Sign In" }}
					</sdButton>
				</a-form-item>
				
				<a-form-item>
					<sdButton
						class="btn-signin"
						htmlType="submit"
						@click="signInGoogle"
						type="danger"
						size="large"
					>
						<img :src="require('@/static/img/google.png')" alt="" />
						<span>Sign in with Google</span>
					</sdButton>
				</a-form-item>
			</a-form>
		</div>
	</AuthWrapper>
</template>
<script>
import { AuthWrapper } from "./style";

import useVuelidate from "@vuelidate/core";
import { required, email, helpers, minLength } from "@vuelidate/validators";

import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";

import store from "@/vuex/store";

import google from '../../utility/google';
import { parseCookie } from '../../utility/utility';

export default {
	name: "SignIn",

	components: { AuthWrapper },

	data() {
		return {
			v$: useVuelidate(),
			formData: {
				email: "",
				password: ""
			},
			referral: window.location.href.split("?referral").pop() !== window.location.href ? window.location.href.split("?referral=").pop() : "",
			store: store
		};
	},

	created() {
		if (document.cookie) this.handleGoogleAuthRedirect();
	},

	methods: {
		async signIn() {
			this.v$.formData.$touch();
			this.$store.dispatch("actions/setLoading", true);
			if (this.v$.formData.$pending || this.v$.formData.$error) return;

			const token = await AuthService.signIn(this.formData);

			this.$store.dispatch("auth/setUserToken", token.data.token);

			const userDetails = await UserService.getUserDetails();

			this.$store.dispatch("auth/setUserDetails", userDetails.data);

			this.$store.dispatch("auth/setUserStep", userDetails.data.step);

			this.$store.dispatch("actions/setLoading", false);

			window.showAlert("success", "Success", "Welcome back!");

			this.$router.push("/");
		},

		signInGoogle() {
			window.location.href = google.getGoogleUrl(`/?referral=${this.referral}`);
		},

		handleGoogleAuthRedirect() {
			const cookies = parseCookie(document.cookie);

			if (cookies.token && cookies.user) {
				console.log(JSON.parse(cookies.user))

				this.$store.dispatch("auth/setUserToken", cookies.token);

				this.$store.dispatch("auth/setUserDetails", JSON.parse(cookies.user));

				this.$store.dispatch("auth/setUserStep", JSON.parse(cookies.user).step);

				this.$router.push("/");
			}

			if (cookies.error_google) {
				window.showAlert('error', 'Error', cookies.error_google);
			}
		}
	},

	validations() {
		return {
			formData: {
				email: {
					required: helpers.withMessage("The field email is required", required),
					email: helpers.withMessage("The field email is not valid", email)
				},

				password: {
					required: helpers.withMessage("The field password is required", required),
					minLength: helpers.withMessage(
						"The minimum number of characters is 8",
						minLength(8)
					)
				}
			}
		};
	}
};
</script>
